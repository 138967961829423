import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import GoalsPage from './pages/GoalsPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<LandingPage/>} />
          <Route path='/2025' element={<GoalsPage/>} />
          <Route path="*" element={<LandingPage/>} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
