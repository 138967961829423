import Goal from "../interfaces/Goal";

const apiUrl = process.env.REACT_APP_API_URL;

async function fetchGoals(): Promise<Goal[]> {
    console.log(apiUrl)
    const response = await fetch(`${apiUrl}/goals`);

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status} ${response.statusText}`);
    }
  
    const data: Goal[] = await response.json();
    return data;
  }

async function toggleGoal(id: number, toggle: boolean): Promise<void> {
const response = await fetch(`${apiUrl}/goals/${id}`, {
    method: "PUT",
    headers: {
    "Content-Type": "application/json",
    },
    body: JSON.stringify(toggle),
});

if (!response.ok) {
    throw new Error(`Failed to toggle goal: ${response.status} ${response.statusText}`);
}
}

export {fetchGoals, toggleGoal}