import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { blue, green, pink } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import Goal from '../interfaces/Goal';
import { fetchGoals, toggleGoal } from '../services/GoalApiService';

const GoalsPage: React.FC = () => {

    const [goals, setGoals] = useState<Goal[]>([]);

    useEffect(() => {
        updateGoals();
    }, [])

    const updateGoals = () => {
        fetchGoals()
            .then(d => setGoals(d));
    }

    const handleCheckbox = (id: number, toggle: boolean) => {
        toggleGoal(id, toggle)
            .then(_ => updateGoals());
    }

    return <div style={{
        height: '100vh',
        backgroundColor: 'white',
        display: "flex",
        justifyContent: "center",
    }}>
        <div style={{paddingTop: '20pt'}}>
            <h1>⭐ Mål for 2025 🎊</h1>
            <FormGroup>
                {goals.map(g => <FormControlLabel 
                    control={
                        <Checkbox checked={g.done}
                            icon={<FavoriteBorder />} 
                            checkedIcon={<Favorite />}
                            onChange={() => handleCheckbox(g.id, !g.done)}
                            sx={{
                                color: g.owner === 'Victoria' ? pink[800] : g.owner === 'Vemund' ? blue[800] : green[800],
                                '&.Mui-checked': {
                                color: g.owner === 'Victoria' ? pink[800] : g.owner === 'Vemund' ? blue[800] : green[800]}}} />} 
                    label={g.label} />)}
            </FormGroup>
        </div>
        
    </div>
}

export default GoalsPage;